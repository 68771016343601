import { BehaviorSubject } from 'rxjs';

const subjectShowAuthModal = new BehaviorSubject(false);
const subjectShowFavoriteAuthModal = new BehaviorSubject(false);
const subjectAuthModalView = new BehaviorSubject('login');
const subjectAuthVerificationToken = new BehaviorSubject('');

const meta = {
  fetched: false,
  updatedAt: new Date(),
};

function onFetch() {
  Object.assign(meta, {
    fetched: true,
    updatedAt: new Date(),
  });
}

export const AuthModal = {
  update: authModal => {
    subjectShowAuthModal.next(authModal);
    onFetch();
  },
  subscribe: setState => subjectShowAuthModal.subscribe(setState),
  getValue: () => subjectShowAuthModal.value,
};

export const AuthModalView = {
  update: authModalView => {
    subjectAuthModalView.next(authModalView);
    onFetch();
  },
  subscribe: setState => subjectAuthModalView.subscribe(setState),
  getValue: () => subjectAuthModalView.value,
};

export const AuthFavoriteModal = {
  update: authModal => {
    subjectShowFavoriteAuthModal.next(authModal);
    onFetch();
  },
  subscribe: setState => subjectShowFavoriteAuthModal.subscribe(setState),
  getValue: () => subjectShowFavoriteAuthModal.value,
};

export const AuthVerificationToken = {
  update: verificationToken => {
    subjectAuthVerificationToken.next(verificationToken);
    onFetch();
  },
  subscribe: setState => subjectAuthVerificationToken.subscribe(setState),
  getValue: () => subjectAuthVerificationToken.value,
};

export default {
  AuthModal,
  AuthModalView,
  AuthFavoriteModal,
  AuthVerificationToken,
};
