/* eslint-disable indent */
import Cookies from 'universal-cookie';

import { wave } from './config';

const cookies = new Cookies();
const authorization = cookies.get('token');

const headers = authorization
  ? {
      appId: process.env.appId,
      authorization: `Bearer ${authorization}`,
    }
  : {
      appId: process.env.appId,
    };

export async function getSuggestions(query, collectionTypes) {
  try {
    const response = await wave.get('/v3/products/search/suggestions', {
      headers,
      params: {
        collectionTypes,
        term: query,
      },
    });
    return response.data;
  } catch (error) {
    return { error };
  }
}

export async function getSearchResults(
  query,
  collectionTypes,
  category = null,
  pageNumber = 0,
  showBySelection = 'default'
) {
  try {
    const sortMap = {
      default: 'score|desc',
      offer: 'discount|desc',
      alphabetical: 'name|asc',
      highFirst: 'price|desc',
      lowFirst: 'price|asc',
      kiloPrice: 'unitOfMeasurementFinalPrice|asc',
    };

    const response = await wave.get('/v3/products/search', {
      headers,
      params: {
        collectionTypes,
        term: query,
        category,
        pageNumber,
        sort: sortMap[showBySelection],
      },
    });
    return response.data;
  } catch (error) {
    return { error };
  }
}
