import styles from './MainLogo.module.scss';

export default function MainLogo() {
  return (
    <img
      className={styles.logo}
      width="160"
      height="56"
      src={`/assets/${process.env.appName}/logo.svg`}
      alt={`${process.env.appName} logo`}
      data-cy="logo"
    />
  );
}
