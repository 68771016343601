import Link from 'next/link';
import { FaUserCircle } from 'react-icons/fa';

import styles from './HeaderUser.module.scss';

function HeaderUser({ user, guestUser }) {
  if (guestUser?.email) {
    return (
      <Link href="/guest">
        <a className={styles.user} data-cy="guest-user" aria-label="go to guest-user page">
          <FaUserCircle className={styles.iconUser} alt="go to guest-user page" />
          <span className={styles.text}>Επισκέπτης</span>
        </a>
      </Link>
    );
  }
  if (!user?._id) {
    return (
      <Link href="/login">
        <a className={styles.user} data-cy="user" aria-label="go to log in page">
          <FaUserCircle className={styles.icon} alt="go to log in page" />
          <span className={styles.text}>Σύνδεση/Εγγραφή</span>
        </a>
      </Link>
    );
  }
  return (
    <Link href="/user">
      <a className={styles.user} data-cy="user" aria-label="go to user page">
        <FaUserCircle className={styles.iconUser} alt="go to user page" />
        <span className={styles.nameText}>{user.firstName}</span>
      </a>
    </Link>
  );
}

export default HeaderUser;
