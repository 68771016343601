import { useMemo } from 'react';

import CookieBar from '../CookieConsent/CookieBar';
import DownloadApp from '../DownloadApp/DownloadApp';
import FooterLinks from '../FooterLinks/FooterLinks';
import FooterPaymentMethods from '../FooterPaymentMethods/FooterPaymentMethods';

import styles from './Footer.module.scss';
import Newsletter from './Newsletter';
import Social from './Social';

export default function Footer({ footerLinks, noCookies }) {
  const socials = footerLinks?.socials?.data || [];
  const appLinks = footerLinks?.appLinks?.data?.[0]?.fields;

  const footerLinksMemo = useMemo(() => <FooterLinks footerLinks={footerLinks} />, [footerLinks]);

  return (
    <footer className={styles.container}>
      {footerLinksMemo}
      <div className={styles.appSector}>
        {process.env.hasApps && (
          <DownloadApp appLinks={appLinks}>
            <p className={styles.downloadAppTitle}>ΚΑΤΕΒΑΣΕ ΤΟ APP</p>
          </DownloadApp>
        )}
        <Social socials={socials} />
        {process.env.hasNewsletter && (
          <div>
            <p className={styles.socialHeader}>ΜΑΘΕ ΠΡΩΤΟΣ ΤΑ ΝΕΑ ΜΑΣ</p>

            <div className={styles.flexCenter}>
              <Newsletter />
            </div>
          </div>
        )}
      </div>
      <div id="newsletter">
        <FooterPaymentMethods />
      </div>

      {!noCookies && <CookieBar />}
    </footer>
  );
}
