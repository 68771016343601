import dynamic from 'next/dynamic';
import Link from 'next/link';
import Router from 'next/router';
import React, { useState, useEffect, Suspense } from 'react';
import Cookies from 'universal-cookie';

import { createAddressUser } from '../../api/wave/users';
import { IsAddressModalOpen } from '../../store/addressModal';
import { AuthFavoriteModal } from '../../store/authModalShow';
import GuestUserStore from '../../store/guestUser';
import User from '../../store/user';
import { addToAnalytics } from '../../utils/analytics';
import HeaderCart from '../HeaderCart/HeaderCart';
import HeaderUser from '../HeaderUser/HeaderUser';
import MainLogo from '../MainLogo/MainLogo';
import SearchBar from '../SearchBar/SearchBar';
import ShopsLink from '../ShopsLink/ShopsLink';
import ButtonToTop from '../SquareButton/ButtonToTop';

import Hamburger from './Hamburger/Hamburger';
import styles from './Header.module.scss';

const ConfirmationModal = dynamic(() => import('../ConfirmationModal'), {
  suspense: true,
});
const AddressViewModal = dynamic(() => import('../AddressViewModal/AddressViewModal'), {
  suspense: true,
});
const MenuModal = dynamic(() => import('../MenuModal/MenuModal'), {
  suspense: true,
});

export default function Header({ navigation, isFullPageCart = false }) {
  const [currentUser, setCurrentUser] = useState('');
  const [guestUser, setGuestUser] = useState({});
  const [showMenu, setShowMenu] = useState(false);
  const [noUserFavoritesModal, setNoUserFavoritesModal] = useState(false);
  const [openAddressModal, setOpenAddressModal] = useState('close');

  const cookies = new Cookies();

  function showMobileMenu() {
    setShowMenu(!showMenu);
  }

  function closeAddressModal() {
    IsAddressModalOpen.update('close');
  }

  async function addAddress(newAddress) {
    addToAnalytics('addAddres', undefined, [['option', 'submit']]);
    const addressToCreate = { address: [{ ...newAddress }] };
    const token = cookies.get('token');
    const response = await createAddressUser(token, addressToCreate);
    if (typeof response === 'string') {
      closeAddressModal();
      return;
    }
    User.addAddress(response?.address);
    closeAddressModal();
    Router.push('/cart', '/cart');
  }

  useEffect(() => {
    const user$ = User.subscribe(setCurrentUser);
    const addressModal$ = IsAddressModalOpen.subscribe(setOpenAddressModal);
    const authFavoriteModal$ = AuthFavoriteModal.subscribe(setNoUserFavoritesModal);
    const guestUser$ = GuestUserStore.subscribe(setGuestUser);

    return () => {
      user$.unsubscribe();
      authFavoriteModal$.unsubscribe();
      addressModal$.unsubscribe();
      guestUser$.unsubscribe();
    };
  }, []);

  return (
    <header>
      <nav className={styles.container}>
        <section className={styles.leftContainer} title="Home">
          <Link href="/">
            <a className={styles.logoContainer} data-cy="logo-home">
              <MainLogo />
            </a>
          </Link>
        </section>
        <section className={styles.subContainer}>
          <section className={styles.middleContainer}>
            <ShopsLink />
            <SearchBar />
          </section>
          <section className={styles.rightContainer}>
            <HeaderUser user={currentUser} guestUser={guestUser} />
            <HeaderCart isFullPageCart={isFullPageCart} />
            <Hamburger hamburgerClick={showMobileMenu} />
          </section>
        </section>
      </nav>
      {noUserFavoritesModal && (
        <Suspense fallback={<p />}>
          <ConfirmationModal
            onClickBack={() => {
              AuthFavoriteModal.update(false);
              Router.push('/login', '/login');
            }}
            onClickContinue={() => {
              AuthFavoriteModal.update(false);
              Router.push('/register', '/register');
            }}
            backBtnText="Είσοδος"
            continueBtnText="Εγγραφή"
            modalLabel="Action modal"
            modalClose={() => AuthFavoriteModal.update(false)}
          >
            <div className={styles.modalContainer}>
              <img className={styles.modalIcon} src="/assets/global/favorite-cart.png" alt="cart with a heart" />
              <p className={styles.modalText}>
                Παρακαλούμε συνδεθείτε ή εγγραφείτε στην εφαρμογή για να προσθέσετε κάποιο προϊόν στα αγαπημένα σας.
              </p>
            </div>
          </ConfirmationModal>
        </Suspense>
      )}

      {openAddressModal === 'open' && (
        <Suspense fallback={<p />}>
          <AddressViewModal
            isOpen={openAddressModal === 'open'}
            setIsOpen={closeAddressModal}
            onSubmitCB={addAddress}
          />
        </Suspense>
      )}

      <section className={styles.middleContainerSmallScreen}>
        <ShopsLink />
        <SearchBar />
      </section>

      {showMenu && (
        <Suspense fallback={<p />}>
          <MenuModal
            isOpen={showMenu}
            setIsOpen={setShowMenu}
            navigation={navigation}
            onClickMenuOptions={showMobileMenu}
          />
        </Suspense>
      )}
      <ButtonToTop />
    </header>
  );
}
