import cn from 'classnames';

import styles from './Buttons.module.scss';

export function PrimaryButton({ children, onClick = () => {}, ...rest }) {
  return (
    <button className={cn(styles.button, styles.primaryButton)} type="button" onClick={onClick} {...rest}>
      {children}
    </button>
  );
}

export function BrandButton({ children, onClick = () => {}, ...rest }) {
  return (
    <button className={cn(styles.button, styles.brandButton)} type="button" onClick={onClick} {...rest}>
      {children}
    </button>
  );
}

export function SecondaryButton({ children, onClick = () => {}, ...rest }) {
  return (
    <button className={cn(styles.button, styles.secondaryButton)} type="button" onClick={onClick} {...rest}>
      {children}
    </button>
  );
}
