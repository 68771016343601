import { FaFacebookF, FaInstagram, FaTwitter } from 'react-icons/fa';
import { ImYoutube } from 'react-icons/im';

import styles from './Social.module.scss';

function Social({ socials }) {
  const title = socials?.[0]?.title;
  const links = socials?.[0]?.fields;

  if (!links) {
    return null;
  }

  return (
    <div>
      <p className={styles.socialHeader}>{title}</p>
      <div className={styles.social}>
        {links.facebookLink && (
          <a href={links.facebookLink} aria-label="βρείτε μας στο facebook">
            <div className={styles.socialItem}>
              <FaFacebookF className={styles.socialIcon} />
            </div>
          </a>
        )}
        {links.twitterLink && (
          <a href={links.twitterLink} aria-label="βρείτε μας στο twitter">
            <div className={styles.socialItem}>
              <FaTwitter className={styles.socialIcon} />
            </div>
          </a>
        )}
        {links.instagramLink && (
          <a href={links.instagramLink} aria-label="βρείτε μας στο instagram">
            <div className={styles.socialItem}>
              <FaInstagram className={styles.socialIcon} />
            </div>
          </a>
        )}
        {links.youtubeLink && (
          <a href={links.youtubeLink} aria-label="βρείτε μας στο youtube">
            <div className={styles.socialItem}>
              <ImYoutube className={styles.socialIcon} />
            </div>
          </a>
        )}
      </div>
    </div>
  );
}

export default Social;
