import styles from './DownloadApp.module.scss';

function DownloadApp({ appLinks, children }) {
  if (!appLinks || (!appLinks?.androidLink && !appLinks?.iosLink)) {
    return null;
  }
  return (
    <div className={styles.body}>
      {children}
      <div className={styles.imageContainer}>
        {appLinks?.androidLink && (
          <a className={styles.mobileLinks} href={appLinks?.androidLink}>
            <img
              className={styles.img}
              loading="lazy"
              width="124"
              height="40"
              src="/assets/global/play-store.png"
              alt="google play store"
            />
          </a>
        )}
        {appLinks?.iosLink && (
          <a className={styles.mobileLinks} href={appLinks?.iosLink}>
            <img
              className={styles.img}
              loading="lazy"
              width="124"
              height="40"
              src="/assets/global/app-store.png"
              alt="apple app store"
            />
          </a>
        )}
      </div>
    </div>
  );
}

export default DownloadApp;
