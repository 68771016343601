import Link from 'next/link';
import { useState, useEffect } from 'react';

import Cookie from '../../store/cookies';
import jsonStorage from '../../utils/json-storage';
import { PrimaryButton, SecondaryButton } from '../Buttons/Buttons';

import styles from './CookieBar.module.scss';

export default function CookieBar() {
  const [showBanner, setShowBanner] = useState(false);

  function onClickSettings() {
    const cookiesUpdate = {
      necessary: true,
      performance: false,
      ad: false,
      ux: false,
    };
    Cookie.update(cookiesUpdate);
  }

  function onClickAccept(option) {
    if (option === 'all') {
      const cookiesUpdate = {
        necessary: true,
        performance: true,
        ad: true,
        ux: true,
      };
      Cookie.update(cookiesUpdate);
      setShowBanner(false);
      return;
    }
    const cookiesUpdate = {
      necessary: true,
      performance: false,
      ad: false,
      ux: false,
    };
    Cookie.update(cookiesUpdate);
    setShowBanner(false);
  }

  useEffect(() => {
    const storage = window.localStorage;
    const privacy = jsonStorage.get('Kritikos:privacy', { storage });
    const privacyNew = jsonStorage.get(`${process.env.appName}:privacy`, { storage });
    if (!privacy && !privacyNew) {
      setShowBanner(true);
    } else {
      const updatedCookies = privacy || privacyNew;
      Cookie.update(updatedCookies);
      jsonStorage.remove('Kritikos:privacy', { storage });
    }
    const cookies$ = Cookie.subscribe();
    return () => cookies$.unsubscribe();
  }, []);

  return (
    showBanner && (
      <section className={styles.bannerContainer}>
        <div className={styles.content}>
          <h3 className={styles.heading}>Διαχείριση cookies και παρόμοιων τεχνολογιών</h3>
          <div className={styles.textContainer}>
            Χρησιμοποιούμε εργαλεία όπως τα cookies για να σου προσφέρουμε μία κορυφαία προσωποποιημένη εμπειρία, για να
            σε βοηθήσουμε να βρεις ευκολότερα αυτό που ψάχνεις, καθώς και για την ανάλυση της επισκεψιμότητάς μας.
          </div>
        </div>
        <div className={styles.container}>
          <Link href="/cookies">
            <a className={styles.link} onClick={onClickSettings} data-cy="settings">
              Ρυθμίσεις
            </a>
          </Link>
          <div className={styles.btnContainer}>
            <SecondaryButton data-cy="reject-cookies" onClick={() => onClickAccept('necessary')}>
              <strong>Απόρριψη</strong>
            </SecondaryButton>
            <PrimaryButton onClick={() => onClickAccept('all')}>
              <strong>Αποδοχή</strong>
            </PrimaryButton>
          </div>
        </div>
      </section>
    )
  );
}
