import dayjs from 'dayjs';
import { IoMdHeart } from 'react-icons/io';

import styles from './FooterPaymentMethods.module.scss';

export default function FooterPaymentMethods() {
  const year = dayjs().year();
  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <div className={styles.credits}>
          <p className={styles.text}>
            {`© Copyright ${year}`}
            <strong className={styles.brand}> {process.env.footerBrand}</strong>. All Rights Reserved
          </p>
          <p className={styles.text}>
            <span>Made with </span>
            <IoMdHeart className={styles.heartML} width="10" height="10" alt="love" />
            <span> by </span>
            <a href="//thinkdesquared.com/" className={styles.text} target="_blank" rel="noopener noreferrer">
              Desquared
            </a>
          </p>
        </div>
        {process.env.hasOnlinePayment && (
          <div className={styles.paymentMethods}>
            <img loading="lazy" className={styles.nbg} width="62" height="32" src="/assets/global/nbg.png" alt="nbg" />
            <div className={styles.divider} />
            {process.env.paymentCards.map(card => (
              <img
                key={card}
                loading="lazy"
                className={styles.paymentMethodImg}
                width="32"
                height="32"
                src={`/assets/global/${card}.png`}
                alt={card}
              />
            ))}
          </div>
        )}
      </div>
    </section>
  );
}
