import Link from 'next/link';
import { MdLocationPin } from 'react-icons/md';

import styles from './ShopsLink.module.scss';

export default function ShopsLink() {
  return (
    <Link href={process.env.storesRedirect}>
      <a className={styles.container} aria-label="find shop">
        <MdLocationPin className={styles.icon} alt="pin" />
        <span className={styles.text}>Βρες το κατάστημά σου</span>
      </a>
    </Link>
  );
}
