import cn from 'classnames';
import { useEffect, useState } from 'react';
import { MdKeyboardArrowUp } from 'react-icons/md';

import styles from './ButtonToTop.module.scss';

function ButtonToTop() {
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  function onClickBackToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  function handleScroll() {
    const distanceFromTop =
      window.pageYOffset !== undefined
        ? window.pageYOffset
        : (document.documentElement || document.body.parentNode || document.body).scrollTop;
    if (distanceFromTop > 220) {
      setIsButtonVisible(true);
    } else {
      setIsButtonVisible(false);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <button
      type="button"
      onClick={onClickBackToTop}
      className={cn(styles.button, { [styles.hideButton]: !isButtonVisible })}
    >
      <MdKeyboardArrowUp className={styles.icon} alt="κουμπί scroll to the top"/>
    </button>
  );
}

export default ButtonToTop;
