import cn from 'classnames';
import Link from 'next/link';

import styles from './Navigation.module.scss';

function isSubnavValid(subnav) {
  if (!subnav || !subnav.length) return false;
  return !subnav.some(v => !v['subnav-title'] || !v['subnav-url']);
}

export default function Navigation({ navigation = [] }) {
  return (
    <section className={styles.container}>
      <nav className={styles.navbar}>
        {navigation.map(navbar => (
          <div className={styles.relative} key={navbar.uuid}>
            <Link href={navbar.fields.url}>
              <a
                className={cn(styles.link, {
                  [styles.submenu]: isSubnavValid(navbar.fields.subnav),
                })}
              >
                {navbar.title}
              </a>
            </Link>
            {isSubnavValid(navbar.fields.subnav) && (
              <div className={styles.submenuDropdown} data-cy="submenu">
                {navbar.fields.subnav.map(subnav => (
                  <Link key={subnav['subnav-title']} href={subnav['subnav-url']}>
                    <a data-cy={subnav['subnav-title']} className={styles.subnavLink}>
                      {subnav['subnav-title'] || ''}
                    </a>
                  </Link>
                ))}
              </div>
            )}
          </div>
        ))}
      </nav>
    </section>
  );
}
