import dynamic from 'next/dynamic';
import { useState, Suspense } from 'react';
import { FaTelegramPlane } from 'react-icons/fa';

import styles from './Newsletter.module.scss';

const GenericModalV2 = dynamic(() => import('../GenericModalV2'), {
  suspense: true,
});

export default function Newsletter() {
  const [openModal, setOpenModal] = useState(false);

  const success = `Ευχαριστούμε για την εγγραφή σας στο Newsletter των Supermarket ${process.env.displayName}`;

  return (
    <>
      <form
        className={styles.newsletter}
        action="//www.contactpigeon.com/apps/en/stem/subscribe.html/"
        method="post"
        name="form_sf_8874"
        onSubmit={() => setOpenModal(true)}
      >
        <input type="hidden" value="8874" name="gm_pid" />
        <input
          className={styles['newsletter-input']}
          type="email"
          id="sfemail"
          name="sfemail"
          placeholder="Εγγραφή στο Newsletter"
          required
        />
        <button type="submit" name="regme" className={styles.newsletterButton} aria-label="newsletter">
          <div className={styles.iconBorder}>
            <FaTelegramPlane className={styles.iconNewsletter} />
          </div>
        </button>
      </form>
      {openModal && (
        <Suspense fallback={<p />}>
          <GenericModalV2 mainText={success} isOpen={openModal} modalLabel="Info modal" />
        </Suspense>
      )}
    </>
  );
}
