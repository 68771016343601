import { getGreeklish } from 'greek-utilities';
import isEmpty from 'lodash/isEmpty';
import { BehaviorSubject, concat } from 'rxjs';
import { filter, switchMap, map, skip } from 'rxjs/operators';

import DeliveryMode from './deliveryMode';
import ProductsCollection from './productsCollection';

export const MIN_QUERY_LENGTH = 3;

const query$ = new BehaviorSubject('');
const search$ = query$.pipe(
  filter(v => v.length >= MIN_QUERY_LENGTH),
  map(getGreeklish),
  switchMap(val => {
    const service = DeliveryMode.getValue();
    const collectionType = DeliveryMode.getCollectionAsNumber(service);

    const products = ProductsCollection.getProductsForCollections(collectionType);
    const searchResults = products?.filter(product => {
      const terms = product?.searchTerms?.reduced || '';
      return val.split(' ').every(v => terms.includes(v));
    });
    const exactQueryMatch = searchResults.filter(product => {
      const terms = product?.searchTerms?.reduced || '';
      const queryOnTerms = terms.split(' ').filter(term => {
        return term.startsWith(val);
      });
      return !isEmpty(queryOnTerms);
    });

    if (isEmpty(exactQueryMatch)) {
      return [searchResults];
    }

    return [exactQueryMatch];
  })
);

const ProductsSearchStore = {
  updateQuery(query) {
    query$.next(query);
  },
  query: setState => query$.subscribe(setState),
  getValue: () => query$.value,

  searchedProducts$: concat(ProductsCollection.productsReady$, search$).pipe(skip(1)),
};

export default ProductsSearchStore;
