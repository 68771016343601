import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useState, useEffect, Suspense } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { GoSearch } from 'react-icons/go';

import { getSuggestions } from '../../api/Search';
import DeliveryMode from '../../store/deliveryMode';
import LoaderStore from '../../store/loader';
import ProductsSearchStore, { MIN_QUERY_LENGTH } from '../../store/productsSearch';
import { viewSearchResults } from '../../utils/analytics';
import { getValidObject } from '../../utils/objectManipulation';
import SuggestedSearchResults from '../SuggestedSearchResults/SuggestedSearchResults';

import styles from './SearchBar.module.scss';

const OrderAlertModal = dynamic(() => import('../OrderAlertModal/OrderAlertModal'), {
  suspense: true,
});

export default function SearchBar() {
  const router = useRouter();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [hideSuggestions, setHideSuggestions] = useState(true);
  const [collectionTypes, setCollectionTypes] = useState([process.env.defaultCollectionType]);
  const [suggestions, setSuggestions] = useState({});
  const [suggestionsFlag, setSuggestionsFlag] = useState(false);

  useEffect(() => {
    const deliveryMode$ = DeliveryMode.subscribe(mode => {
      const _collectionTypes = DeliveryMode.getCollectionAsNumber(mode);
      setCollectionTypes(_collectionTypes);
    });
    return () => {
      deliveryMode$.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const query$ = ProductsSearchStore.query(setQuery);
    return () => {
      query$.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!router.isReady) return;
    const searchTerm = { ...getValidObject('product', router?.query?.product) };
    ProductsSearchStore.updateQuery(searchTerm?.product || '');
  }, [router.isReady]);

  useEffect(() => {
    async function fetchSuggestions() {
      const _suggestions = await getSuggestions(query, collectionTypes);
      setSuggestions(_suggestions);
      setSuggestionsFlag(false);
    }

    if (suggestionsFlag && query.length > 2) {
      fetchSuggestions();
    }
  }, [query, collectionTypes]);

  function updateURL(routerAction, product = query) {
    const params = {
      product,
      ...getValidObject('sortBy', null),
    };

    router[routerAction](
      {
        pathname: '/search',
        query: params,
      },
      undefined,
      { shallow: false }
    );
  }

  function onChangeQuery({ target: { value } }) {
    setQuery(value);
    setHideSuggestions(false);
    if (value.length > 2) {
      ProductsSearchStore.updateQuery(value);
      viewSearchResults(value);
      setSuggestionsFlag(true);
    }
  }

  function onSubmit(e) {
    e.preventDefault();
    if (!query || query.length === 0) {
      return;
    }
    LoaderStore.addEvent('ROUTE_CHANGE');
    updateURL('push');
  }

  function onBlurSearchBar() {
    setTimeout(() => {
      setHideSuggestions(true);
      setSuggestionsFlag(false);
    }, 300);
  }

  function onFocusSearchBar() {
    setSuggestionsFlag(true);
    setHideSuggestions(false);
  }

  return (
    <>
      <form className={styles.container} onSubmit={onSubmit}>
        <DebounceInput
          minLength={1}
          debounceTimeout={300}
          className={styles.input}
          onBlur={onBlurSearchBar}
          onFocus={onFocusSearchBar}
          value={query}
          onChange={onChangeQuery}
          placeholder="Αναζήτησε προϊόντα"
          data-cy="search-bar"
        />
        <button className={styles.submit} aria-label="Αναζήτηση">
          <GoSearch className={styles.icon} alt="Αναζήτηση" aria-hidden="true" />
        </button>
        {query.length > 2 && !hideSuggestions && (
          <Suspense fallback={<p />}>
            <SuggestedSearchResults query={query} suggestions={suggestions} />
          </Suspense>
        )}
      </form>
      {isModalOpen && (
        <Suspense fallback={<p />}>
          <OrderAlertModal
            alertText={`Ο όρος αναζήτησης πρέπει να είναι πάνω από ${MIN_QUERY_LENGTH - 1} γράμματα.`}
            modalLabel="Μη αποδεκτός όρος αναζήτησης"
            isModalOpen={isModalOpen}
            callback={() => setIsModalOpen(false)}
          />
        </Suspense>
      )}
    </>
  );
}
