import Link from 'next/link';

import styles from './SuggestedSearchProduct.module.scss';

function SuggestedSearchProduct({ product }) {
  if (!product) return null;
  const base = product.slug.substring(0, product.slug.indexOf('/'));
  return (
    <Link href={`/${base}${product.slug.replace(base, '')}`} key={product.id}>
      <div className={styles.link}>
        <img className={styles.img} src={`${product.image.baseUrl}${product.image.primary}`} alt={product.title} />
        <div>
          <p className={styles.resultTitle}>{product.title}</p>
          <div className={styles.productTextsContainer}>
            {product.webSticker && (
              <div className={styles.webStickerContainer}>
                <span className={styles.badge}>{product.webSticker}</span>
              </div>
            )}
            <p className={styles.productSubtitle}>{product.description}</p>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default SuggestedSearchProduct;
