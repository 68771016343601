import styles from './Hamburger.module.scss';

export default function Hamburger({ hamburgerClick }) {
  return (
    <button
      type="button"
      className={styles.navHamburger}
      onClick={() => {
        hamburgerClick();
      }}
      aria-label="hamburger menu"
    >
      <span className={styles.line} />
      <span className={styles.line} />
      <span className={styles.line} />
    </button>
  );
}
