import Link from 'next/link';
import { AiFillPrinter } from 'react-icons/ai';
import { FiMapPin } from 'react-icons/fi';
import { ImPhone } from 'react-icons/im';
import { MdEmail } from 'react-icons/md';

import MainLogo from '../MainLogo/MainLogo';

import styles from './FooterLinks.module.scss';

export const contactInformation = {
  kritikos: [
    {
      label: '801 11 232425',
      icon: <ImPhone className={styles.contactIcon} alt="phone" />,
      type: 'phone',
    },
    {
      label: '210 55 58 832',
      icon: <ImPhone className={styles.contactIcon} alt="phone" />,
      type: 'phone',
    },
  ],
  ariadni: [
    {
      label: '2811100500',
      icon: <ImPhone className={styles.contactIcon} alt="phone" />,
      type: 'phone',
    },
    {
      label: '2810251546',
      icon: <AiFillPrinter className={styles.contactIcon} alt="printer" />,
      type: 'fax',
    },
    {
      label: 'info@crmsa.gr',
      icon: <MdEmail className={styles.contactIcon} alt="email" />,
      type: 'email',
    },
    {
      label: 'Ειρήνης και Φιλίας 207-209, Μαλάδες Ηρακλείου, 71500',
      icon: <FiMapPin className={styles.contactIcon} alt="email" />,
      type: 'location',
    },
  ],
};

export default function FooterLinks({ footerLinks }) {
  const links = footerLinks?.footerLinks?.data || [];

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <div className={styles.contactSector}>
          <div className={styles.logoContainer}>
            <Link href="/">
              <a className={styles.logo}>
                <MainLogo />
              </a>
            </Link>
          </div>
          <p className={styles.contactNumber}>Εξυπηρέτηση πελατών</p>

          {contactInformation[process.env.appName].map(n => (
            <div key={n.label}>
              {n.type === 'phone' ? (
                <a className={styles.tel} href={`tel:${n.label}`}>
                  {n.icon}
                  <span>{n.label}</span>
                </a>
              ) : (
                <div className={styles.tel}>
                  {n.icon}
                  <span>{n.label}</span>
                </div>
              )}
            </div>
          ))}
        </div>
        {links.map(footerSector => {
          return (
            <div className={styles.sector} key={footerSector.title}>
              <h3 className={styles.header}>{footerSector.title}</h3>
              {footerSector.fields.footerLink.map(footerlink => {
                return (
                  <Link href={footerlink['footerLink-url']} key={footerlink['footerLink-title']}>
                    <a className={styles.link}>{footerlink['footerLink-title']}</a>
                  </Link>
                );
              })}
            </div>
          );
        })}
      </div>
    </section>
  );
}
