import cn from 'classnames';
import size from 'lodash/size';
import dynamic from 'next/dynamic';
import { useState, useEffect, Suspense } from 'react';
import { IoMdCart } from 'react-icons/io';

import CartStore, { productsQuantities } from '../../store/cart';

import styles from './HeaderCart.module.scss';

const Cart = dynamic(() => import('../Cart/Cart'), {
  suspense: true,
});
const SwitchServiceModal = dynamic(() => import('../SwitchServiceModal/SwitchServiceModal'), {
  suspense: true,
});

export default function HeaderCart({ isFullPageCart }) {
  const [numProductsInCart, setNumProductsInCart] = useState(0);
  const [isCartVisible, setIsCartVisible] = useState(false);
  const [isModalSwitchOpen, setIsModalSwitchOpen] = useState(false);

  useEffect(() => {
    const productsInCart$ = productsQuantities.subscribe(products => {
      const numOfProducts = size(products);
      setNumProductsInCart(numOfProducts);
    });
    const cartIsVisible$ = CartStore.getIsVisble$().subscribe(_cartVisible => {
      setIsCartVisible(_cartVisible);
    });
    const isCartModalOpen$ = CartStore.getIsModalOpen$().subscribe(setIsModalSwitchOpen);

    return () => {
      productsInCart$.unsubscribe();
      cartIsVisible$.unsubscribe();
      isCartModalOpen$.unsubscribe();
    };
  }, []);

  return (
    <div className={styles.cartHeaderContainer}>
      <button
        type="button"
        className={cn(styles.cart, { [styles.isFullPageCart]: isFullPageCart })}
        onClick={() => {
          if (isFullPageCart) return;
          CartStore.toggleVisibility();
        }}
        data-cy="header-show-cart"
        aria-label="my cart"
      >
        <div className={styles.cartIconContainer}>
          <IoMdCart className={styles.icon} alt="Cart" />
          {numProductsInCart !== 0 && <span className={styles.numProductsText}>{numProductsInCart}</span>}
        </div>
        <span className={styles.text}>Το καλάθι μου</span>
      </button>
      {isCartVisible && (
        <div className={styles.cartContainer}>
          <Suspense fallback={<p />}>
            <Cart />
          </Suspense>
        </div>
      )}
      {isModalSwitchOpen && (
        <Suspense fallback={<p />}>
          <SwitchServiceModal isOpen={isModalSwitchOpen} />
        </Suspense>
      )}
    </div>
  );
}
