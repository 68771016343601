import isEmpty from 'lodash/isEmpty';
import Link from 'next/link';
import { GoSearch } from 'react-icons/go';
import { RiHistoryLine } from 'react-icons/ri';

import SuggestedSearchProduct from '../SuggestedSearchProduct/SuggestedSearchProduct';

import styles from './SuggestedSearchResults.module.scss';

const suggestionsText = {
  el: {
    searchResults: 'Προτάσεις αναζήτησης',
    suggestedProducts: 'Προϊόντα για εσένα',
    catalogueProducts: 'Προϊόντα καταλόγου',
    in: 'σε',
    all: 'όλες',
    categories: 'τις κατηγορίες',
  },
};
export default ({ query, suggestions }) => {
  if (query.length < 3) return null;

  return (
    !isEmpty(suggestions.categories) && (
      <div className={styles.container}>
        <p className={styles.title}>{suggestionsText.el.searchResults}</p>
        <Link href={`/search?product=${query}`}>
          <div className={styles.link}>
            <p className={styles.resultTitle}>
              <GoSearch className={styles.icon} alt="Αναζήτηση" aria-hidden="true" />
              {query}
            </p>
            <p className={styles.resultSubtitle}>
              - {suggestionsText.el.in} {suggestionsText.el.all} {suggestionsText.el.categories}
            </p>
          </div>
        </Link>
        {suggestions.categories.map(suggestion => (
          <Link
            href={`/search?category=${suggestion.category._id}&product=${suggestion.term}`}
            key={suggestion.category._id}
          >
            <div className={styles.link}>
              <p className={styles.resultTitle}>
                {suggestion.previouslySearched ? (
                  <RiHistoryLine className={styles.icon} alt="history" aria-hidden="true" />
                ) : (
                  <GoSearch className={styles.icon} alt="search" aria-hidden="true" />
                )}

                {suggestion.term}
              </p>
              <p className={styles.resultSubtitle}>
                - {suggestionsText.el.in} {suggestion.category.name}
              </p>
            </div>
          </Link>
        ))}

        {!isEmpty(suggestions.suggestedProducts) && (
          <>
            <p className={styles.title}>{suggestionsText.el.suggestedProducts}</p>
            {suggestions.suggestedProducts.map(product => (
              <SuggestedSearchProduct product={product} />
            ))}
          </>
        )}

        {!isEmpty(suggestions.catalogProducts) && (
          <>
            <p className={styles.title}>{suggestionsText.el.catalogueProducts}</p>
            {suggestions.catalogProducts.map(product => (
              <SuggestedSearchProduct product={product} />
            ))}
          </>
        )}
      </div>
    )
  );
};
