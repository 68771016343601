import { BehaviorSubject } from 'rxjs';

const isAddressModalOpenSubject = new BehaviorSubject(false);

export const IsAddressModalOpen = {
  update: value => {
    isAddressModalOpenSubject.next(value);
  },
  subscribe: setState => isAddressModalOpenSubject.subscribe(setState),
  getValue: () => isAddressModalOpenSubject.value,
};

export default { IsAddressModalOpen };
